import React, {  } from "react";
import logo from '../logo.svg';

const Home = () => {
    return(
        <>
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p>Edit <code>src/App.js</code> and save to reload.</p>
                    <a className="App-link" href="/api/user/resetpassword" target="_blank" rel="noopener noreferrer">Learn React</a>
                </header>
            </div>
        </>
    )
}

export default Home;