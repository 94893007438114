import qs from "qs"
import axios from "axios";
import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { immediateToast } from "izitoast-react";

const ForgotPassword = () => {
    const { id, email } = useParams();
    const [pass1, setPass1] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [passError, setPassError] = useState("")

    const handleBlurs = (value) => {
        if (pass1 === value) {
            setDisabled(false);
            setPassError("Password matched")
        } else {
            setDisabled(true)
            setPassError("Passwords do not match");
        }
    }
    return(
        <>
            <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={5}>
                            <Card className="p-4">
                                <Card.Body>
                                    <Formik
                                        initialValues={{ id:id, email:email, pass1: '', pass2: '' }}
                                        validate={(values) => {
                                        const errors = {}
                                        if (!values.pass1) {
                                            errors.pass1 = 'Required'
                                        }
                                        if (!values.pass2) {
                                            errors.pass2 = 'Required'
                                        }
                                        return errors
                                        }}
                                        onSubmit={(values, { resetForm }) => {
                                            const config = {
                                                headers: {
                                                'Content-Type': 'application/x-www-form-urlencoded',
                                                },
                                            }

                                            axios.post(process.env.REACT_APP_BACKEND_URL+"api/user/changepassword", qs.stringify(values), config)
                                            .then((response) => {
                                                immediateToast('success', {
                                                    title: response.data.title,
                                                    message: response.data.description,
                                                    timeout: 2000,
                                                    position: "topRight"
                                                });
                                                resetForm();
                                                setPassError("");
                                            })
                                            .catch((error) => {
                                                if (error.response.data.code === "SUSPENDED") {
                                                    immediateToast('warning', {
                                                        title: error.response.data.description,
                                                        message: "",
                                                        timeout: 2000,
                                                        position: "topRight"
                                                    });
                                                } else {
                                                    immediateToast('error', {
                                                        title: error.response.data.description,
                                                        message: "",
                                                        timeout: 2000,
                                                        position: "topRight"
                                                    });
                                                }
                                            })
                                        }}
                                    >
                                        {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        /* and other goodies */
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <h2>Reset Password</h2>
                                                {/* <p className="text-medium-emphasis">Sign In to your account</p> */}
                                                <Form.Group className="mb-3 mt-3">
                                                    <Form.Label>New Password</Form.Label>
                                                    <Form.Control type="password" 
                                                        className="form-control"
                                                        id="pass1"
                                                        name="pass1"
                                                        onChange={handleChange}
                                                        onBlur={(e) => setPass1(e.target.value)}
                                                        value={values.pass1}
                                                        placeholder="New Password"
                                                        autoComplete="pass1" 
                                                        
                                                    />
                                                    {errors.pass1 && touched.pass1 && errors.pass1}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Confirm New Password</Form.Label>
                                                    <Form.Control 
                                                        type="text"
                                                        className="form-control"
                                                        id="pass2"
                                                        name="pass2"
                                                        onChange={handleChange}
                                                        onBlur={(e) => handleBlurs(e.target.value)}
                                                        value={values.pass2}
                                                        placeholder="Confirm New Password"
                                                        autoComplete="pass2" 
                                                    />
                                                    {errors.pass2 && touched.pass2 && errors.pass2}
                                                </Form.Group>
                                                <Form.Label style={{color: disabled === true ? "red":"green"}}>{passError}</Form.Label>
                                                <Card.Footer style={{background: "none", border:"0px", padding:"0px"}} className="mt-2">
                                                    <Button variant="primary" type="submit" disabled={disabled} style={{width:"100%"}}>Changed</Button>
                                                </Card.Footer>
                                            </Form>
                                        )}
                                    </Formik>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default ForgotPassword;