import './App.css';
import React from 'react';
import "izitoast-react/dist/iziToast.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import your components
import Home from './home/home';
import ForgotPassword from './forgotPassword/ForgotPassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/api/user/resetpassword/:id/:email" element={<ForgotPassword />} />
      </Routes>
    </Router>
  );
}

export default App;
